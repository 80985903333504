<template>
  <header
    class="flex items-center justify-center w-full h-50vh border-b-8 our-products-bg border-primary"
  >
    <div class="font-medium tracking-wide text-center text-white md:w-1/2">
      <h1 class="m-3 text-4xl font-bold uppercase md:text-6xl mt-32">
        {{ $t('Gallery.h1') }}
      </h1>
      <p class="text-2xl font-medium">
        {{ $t('Gallery.description') }}
      </p>
    </div>
  </header>
  <div>
    <div class="my-12">
      <h2 class="text-3xl text-center">
        <span class="gallery-text pb-2">{{ $t('Gallery.h1') }}</span>
      </h2>
      <div class="my-20 gallery-container">
        <div class="gallery" v-for="galleryItem in gallery" :key="galleryItem.id">
          <div class="image-overlay"></div>
          <img :src="URL + galleryItem.path" alt="gallery" />
          <div :class="['gallery-info', $t('lang') == 'en' ? 'text-left' : 'text-right']">
            <p>{{ $t('lang') == 'en' ? galleryItem.name_en : galleryItem.name_ar }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'
import { computed } from '@vue/reactivity'
export default {
  name: 'gallery',

  setup() {
    const store = useStore()
    // const Warehouses = computed(() => store.getWarehouses.Warehouses);
    const gallery = computed(() => store.getters.gallery)

    //  const { t, locale } = useI18n({ useScope: 'global' })

    return {
      // URL: process.env.VUE_APP_URL,
      URL: 'https://api.nowarat.ly/',
      gallery: gallery || [],
      // gallery: [
      //   '/gallery-1.jpg',
      //   '/gallery-3.jpg',
      //   '/gallery-2.jpg',
      //   '/gallery-3.jpg',
      //   '/gallery-1.jpg',
      //   '/gallery-2.jpg',
      // ],
      // locale,
      // t,
    }
  },
}
</script>

<style lang="scss" scoped>
header {
  background: url(../assets/abstract-product-background-empty-white-geometric-podiums-cone-autumn-background-shadows.jpg);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.gallery-text {
  border-bottom: 2px solid rgb(255, 131, 3);
}
.gallery-container {
  padding: 0 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(27%, 1fr));
  gap: 2rem;
}
@media (max-width: 767px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
.gallery-container .gallery {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.gallery-container .gallery .image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s;
}
.gallery-container .gallery img {
  object-fit: cover;
  max-height: 300px;
  width: 100%;
  height: 100%;
}
.gallery-container .gallery .gallery-info {
  position: absolute;
  bottom: 0;
  font-size: 1.5rem;
  color: white;
  width: 100%;
  background: #000000d1;
  padding: 1rem 1.5rem;
  opacity: 0;
  transition: 0.3s;
}
.gallery-container .gallery:hover .image-overlay {
  background-color: #00000052;
}
.gallery-container .gallery:hover .gallery-info {
  opacity: 1;
}
</style>
